.changeColor {
    color: #FFFFFF;
}

.changeColor:hover {
    color: #22c55e;
}

.valueTable {
    font-size: 14px;
}

Td {
    margin: 14px 0px;
    font-size: 14px;
    font-weight: bolder;
}