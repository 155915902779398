
.menu {
    background-color: rgba(40, 40, 40, 0.77);
}

.itemsContainer {
    height: 100%;
    width: 90%;
    padding: 0px 40px;
    justify-content: space-between;
}

.itemContainer {
    transition: 0.2s;
    height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.itemContainer :hover {
    transform: scale(1.02);
}

.itemLabel {
    font-size: 0.9em;
    text-align: center;
    flex-wrap: wrap;
    text-overflow: ellipsis;
    overflow: hidden;
}