.container {
    /* flex: 1;
    flex-direction: column;
    display: flex; */
    width: 100%;
    height: 100vh;
    background-color: black;

}

.tableContainer {
    position: relative;
    height: 100%;
    width: 100%;
    /* overflow-x: hidden;
    overflow-y: auto; */
    transition: 1s;
    margin-bottom: 5px;
}