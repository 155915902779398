.changeColor {
    color: #FFFFFF;
}

.changeColor:hover {
    color: #22c55e;
}

.valueTable {
    font-size: 14px;
}

Td {

    font-size: 14px;
    font-weight: bolder;
}

.completTableHeader {
    width: 150px;
    height: 50px;
}