.Container {
    display: flex;
    align-items: flex-start;
}

.label {
    font-size: 14px;
    text-align: center;
    font-weight: 700;
}

.thinLabel {
    font-size: 14px;
    text-align: 14px;
    font-weight: 500;
    margin-right: 15px;
}